<template>
  <div class="bg-tadawul-5">
    <div class="flex items-center h-full fixed right-0 z-10 ">
      <button
        @click="toggle"
        class="
          bg-libyana
          w-6
          md:w-10
          py-1
          text-white
          font-extrabold
          text-lg
          md:text-3xl
          rounded-l-xl
          flex
          justify-center
          items-center
          focus:outline-none
        "
      >
        +
      </button>
    </div>
    <section
      v-if="info"
      id="info"
      class="inset-0 absolute z-50 overflow-y-scroll bg-libyana"
    >
      <div
        class="max-w-8xl mx-auto py-12 lg:py-20 px-6 md:px-8 lg:px-12 xl:px-0"
      >
        <button
          @click="toggle"
          class="w-6 h-6 rounded-full left-0 focus:outline-none"
        >
          <svg
            :class="`${$i18n.locale}` == 'ar' ? '' : 'transform rotate-180'"
            version="1.1"
            id="Capa_1"
            x="0px"
            y="0px"
            viewBox="0 0 490.667 490.667"
            style="enable-background: new 0 0 490.667 490.667"
            xml:space="preserve"
          >
            <path
              style="fill: white"
              d="M245.333,0C109.839,0,0,109.839,0,245.333s109.839,245.333,245.333,245.333
            s245.333-109.839,245.333-245.333C490.514,109.903,380.764,0.153,245.333,0z"
            />
            <path
              style="fill: #2e3383"
              d="M290.133,104.533c-19.139-18.289-49.277-18.289-68.416,0c-18.893,18.881-18.902,49.503-0.021,68.395
            c0.007,0.007,0.014,0.014,0.021,0.021l19.2,19.2H117.333C87.878,192.149,64,216.027,64,245.483
            c0,29.455,23.878,53.333,53.333,53.333h123.584l-19.2,19.2c-17.522,20.175-15.371,50.734,4.804,68.257
            c18.259,15.858,45.423,15.799,63.612-0.139l118.251-118.251c12.492-12.496,12.492-32.752,0-45.248L290.133,104.533z"
            />
          </svg>
        </button>
        <div class="mt-6 md:mt-10 lg:mt-16 lg:flex">
          <div class="lg:w-3/12 text-white text-lg">
            <div class=" text-2xl md:mb-2">
              + {{ $t("Libyana.project_info.project_info") }}
            </div>
            <br />
            {{ $t("Libyana.project_info.work.1") }} /
            <br class="hidden lg:block" />
            {{ $t("Libyana.project_info.work.2") }} /
            <br class="hidden lg:block" />
            {{ $t("Libyana.project_info.work.3") }} /
          </div>
          <div class="lg:w-9/12 text-white mt-6">
            <p class="text-4xl ">
              {{ $t("Libyana.project_info.description.title") }}
            </p>
            <div class="">
              <p class="mt-6 md:text-justify leading-7 text-lg ">
                {{ $t("Libyana.project_info.description.content_1") }}
              </p>
              <p class="mt-6 md:text-justify leading-7 text-xl ">
                {{ $t("Libyana.project_info.description.content_2") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="hero" class="">
      <section
        class="
        
          md:h-libyana
          h-96
          background_Libyan_hero
          bg-cover
          bg-center
        "
      >
        <div :dir="`${$i18n.locale}` == 'ar' ? 'rtl' : 'ltr'"
          class="container mx-auto px-6 xl:px-12 "
          :class="`${$i18n.locale}` == 'ar' ? 'flex justify-start' : 'flex justify-start'"
        >
          <div class="lg:w-11/12 py-8 md:py-20 text-white ">
            <div
              class="text-2xl  md:text-4xl xl:text-6xl  tajawalbold mt-36 md:mt-libyan_font_hero
              "
            >
              <div class="">
                {{ $t("Libyana.title") }}
              </div>
              <div class="xl:-mt-8">
                {{ $t("Libyana.subtitle") }}
              </div>
              
            </div>
          </div>
        </div>
      </section>
    </section>
    <section class="bg-black py-20 -mt-1">
      <div class="container mx-auto px-6 xl:px-12 md:flex justify-between items-center w-full">
        <div
          :class="`${$i18n.locale}` == 'ar' ? 'xl:pr-40' : ''"
          class="
            md:w-full
            text-white text-5xl
            xl:text-6xl
            font-hairline
            leading-10
            xl:pl-40
          "
        >
          <span class="tajawalbold tracking-ls">{{
            $t("Libyana.section1")
          }}</span>
          <br />
          <br />

          <span class="text-xl xl:text-3xl md:text-justify tajawalRg">{{
            $t("Libyana.section2")
          }}</span>
          <br />
          <span class="text-xl xl:text-3xl md:text-justify tajawalbold">{{
            $t("Libyana.section3")
          }}</span>
        </div>
      </div>
    </section>
    <section class="bg-black mx-auto w-full">
      <img
        src="../assets/images/Libyana/thumbnail.png"
        alt=""
        class="w-full object-cover"
      />
    </section>
    <section
      class="
        background_Libyan
        bg-right bg-no-repeat bg-clip-text
        lg:bg-clip-content
      "
    >
      <div
        class="max-w-6xl mx-auto px-4 lg:px-0"
        :class="`${$i18n.locale}` == 'ar' ? 'flex justify-end' : ''"
      >
        <div class="lg:w-9/12 py-8 md:py-20 max-w-xl">
          <p class="text-lg md:text-5xl lg:text-6xl text-white tajawalRg">
            وين ماتاخدك حياتك,, ومهما كانت إنشغالاتك,, عيش حكايتك, وجدد إتصالك
          </p>
        </div>
      </div>
    </section>
    <section class="mx-auto">
      <img
        src="../assets/images/Libyana/cover_twitter.png"
        alt=""
        class="w-full"
      />
    </section>
    <section class="md:flex items-center">
      <div class="">
        <img
          src="../assets/images/Libyana/Free_Triple_Billboard_Mockup_2.jpg"
          alt=""
        />
      </div>
      <div class="">
        <img src="../assets/images/Libyana/2.jpg" alt="" />
      </div>
    </section>
    <section class="bg-libyana-1 mx-auto px-6 xl:px-12 text-center pt-6">
      <span class="text-3xl xl:text-6xl">STORYBOARDS</span>
    </section>
    <section class="bg-libyana-1 py-12 mx-auto px-6 xl:px-12">
      <img
        src="../assets/images/Libyana/1.png"
        alt=""
        class="w-full object-cover"
      />
    </section>
    <section class="bg-libyana-1 mx-auto px-6 xl:px-12 text-center">
      <span class="text-3xl xl:text-6xl">ST/LLS</span>
    </section>
    <section class="mx-auto">
      <img
        src="../assets/images/Libyana/Asset 4.png"
        alt=""
        class="w-full object-cover"
      />
    </section>
    <div
      class="
        text-xl
        md:text-3xl
        flex
        justify-center
        text-white
        bg-black
        pt-4
        linkwork
        fontRg
        -mt-px
        -mb-1
        z-10
      "
    >
      <router-link :to="`/${$i18n.locale}/almadar`">
        {{ $t("Next-Project") }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      info: false,
      publicPath: process.env.BASE_URL,
    };
  },
  mounted() {
    scrollTo(0, 0);
  },
  methods: {
    toggle() {
      if (this.info == true) {
        this.info = false;
        document.body.style.overflow = "";
      } else {
        this.info = true;
        scrollTo(0, 0);
        document.body.style.overflow = "hidden";
      }
    },
  },
};
</script>

<style>
.background_Libyan {
  background-image: url("../assets/images/Libyana/Scroll Group 5.png");
}
.background_Libyan_hero {
  background-image: url("../assets/images/Libyana/Scroll Group 4.png");
}
.stickyL {
  position: -webkit-sticky;
}
</style>